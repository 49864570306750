import React, { useState } from "react";
import { Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import List from "../../../components/custom-widgets/list";
import MktoForm from "../../../components/mkto-form/mkto-form";
import CommercialCreditCardLogin from "../../../components/login/commercial-credit-card-login";
import Icon from "../../../components/custom-widgets/icon";

import styles from "./small-business-credit-card.module.scss";

//helper functions
import showMktoForm from "../../../helpers/showMktoForm";
import checkMktoFormIsSubmitted from "../../../helpers/checkMktoFormIsSubmitted";
import ZipCodeFilter from "../../../components/zip-code-filter/zip-code-filter";

const SmallBusinessCreditCard = () => {
  const isMktoFormSubmitted = checkMktoFormIsSubmitted();

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Small Business Credit Card"
    }
  ];

  const SEOData = {
    title: "Small Business Credit Card",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Small Business Credit Card"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn about WaFd Bank's small business credit card. With no annual fee, our cash back Visa business card will give back to your pocket. Apply online today!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/small-business-credit-card"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-business-credit-250.jpg"
      }
    ]
  };

  const cashBackBenefitsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Automatic <span class='text-green-60 font-weight-bold'>cash back</span>* statement credit "
      },
      {
        id: 2,
        text: "Contactless cards that allow you to tap and go!"
      },
      {
        id: 3,
        text: "Add it to your Apple, Google, and Samsung digital wallets"
      },
      {
        id: 4,
        text: "Benefits** provided by Visa<sup>&reg;</sup> including Roadside Dispatch<sup>&reg;</sup> and ID Navigator Powered by NortonLifeLock. Visa Signature<sup>&reg;</sup> benefits can also include Travel and Emergency Assistance Services in eligible scenarios."
      }
    ]
  };

  const commercialCardBenefitsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "<span class='text-green-60 font-weight-bold'>Earn 1 point per dollar</span>. Rewards that you can actually use, including travel perks, gift cards, and statement credit options"
      },
      {
        id: 2,
        text: "Expense management to save you time and money"
      },
      {
        id: 3,
        text: "Online Card management keeps everything in one place <a href='/documents/ezcard-quick-reference-guide.pdf' target='_blank' rel='noopener noreferrer' id='ezcard-quick-reference-guide-link'>eZCard Quick Reference Guide</a>"
      },
      {
        id: 4,
        text: "Virtual card options allow you more control to authorize company spending"
      }
    ]
  };

  const cashBackDocumentsListData = [
    {
      id: 1,
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> and Visa Signature<sup>&reg;</sup> Rate",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-rate.pdf"
    },
    {
      id: 2,
      text: "WaFd Bank Cash Back Rewards Visa Signature<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-signature-cardholder-agreement.pdf"
    },
    {
      id: 4,
      text: "WaFd Bank Cash Back Rewards Visa<sup>&reg;</sup> Cardholder Agreement",
      url: "/documents/credit-cards/wafd-bank-cash-back-rewards-visa-cardholder-agreement.pdf"
    },
    {
      id: 5,
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Signature tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(signature-tier-version).pdf"
    },
    {
      id: 6,
      text: "Visa<sup>&reg;</sup> Guide to Benefits (Traditional tier version)",
      url: "/documents/credit-cards/visa-guide-to-benefits-(traditional-tier-version).pdf"
    },
    {
      id: 7,
      text: "ScoreCard<sup>&reg;</sup> Cash Back Rewards Program Rules",
      url: "/documents/credit-cards/scorecard-cash-back-rewards-program-rules.pdf"
    }
  ];

  const commercialCashBackDocumentsListData = [
    {
      id: 1,
      text: `WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred Solution Credit Card Rate`,
      url: "/documents/credit-cards/commercial/commercial-credit-card-cb-rates.pdf"
    },
    {
      id: 2,
      text: "WaFd Cardholder Agreement",
      url: "/documents/credit-cards/commercial/commercial-credit-card-cb-card-holder-agreement.pdf"
    },
    {
      id: 3,
      text: "WaFd Bank Visa<sup>&reg;</sup> Commercial Credit Card Guide to Benefits",
      url: "/documents/credit-cards/commercial/commercial-credit-card-guide-to-benefits-visa.pdf"
    },
    {
      id: 4,
      text: "Cash Advance Addendum to Visa<sup>&reg;</sup> Commercial Card Master Agreement",
      url: "/documents/credit-cards/commercial/commercial-credit-card-cb-cash-advance-addendum-card-master-agreement.pdf"
    },
    {
      id: 5,
      text: "Visa<sup>&reg;</sup> Commercial Card Master Agreement",
      url: "/documents/credit-cards/commercial/commercial-credit-card-cb-visa-master-agreement.pdf"
    },
    {
      id: 6,
      text: "Frequently Asked Questions Regarding the ScoreCard Rewards Program for Visa Commercial Preferred Solution (VCPS)",
      url: "/documents/credit-cards/commercial/faq-scorecard-rewards-program-for-vcps.pdf"
    },
    {
      id: 7,
      text: "Scorecard<sup>&reg;</sup> Bonus Point Program Rules for Visa Commercial Preferred Solution (VCPS)",
      url: "/documents/credit-cards/commercial/scorecard-bonus-point-program-rules-for-vcps.pdf"
    }
  ];

  const [showZipCodeModal, setShowZipCodeModal] = useState(false);
  const handleCloseZipCodeModal = () => setShowZipCodeModal(false);
  const handleShowZipCodeModal = (e) => {
    e.preventDefault();
    setShowZipCodeModal(true);
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      {/* Credit Cards Login Section */}
      <section className="container">
        <div className="row align-items-center justify-content-center d-flex">
          <div className="col-12 col-lg-7 col-xl-8">
            <h1>WaFd Bank Small Business Credit Cards</h1>
            <h4>
              Designed for business owners, these cards offer flexibility and convenience, plus cash back rewards! We
              support our communities by helping businesses and the people within them thrive.
            </h4>
            <h4>
              Apply online or <Link to="/business-banking/near-me">talk with a Community Banker</Link> about how the
              WaFd Bank Small Business Credit Card may be the right choice for you and your business.
            </h4>
            <p className="mb-0">
              Looking for a <Link to="/personal-banking/credit-cards">personal credit card</Link>?
            </p>
          </div>
          <div className="col-12 col-sm-8 col-md-7 col-lg-5 col-xl-4">
            <CommercialCreditCardLogin loginType="Small Business" />
          </div>
        </div>
        <hr />
      </section>

      <section className="container px-0 px-sm-3 mt-sm-5">
        <div className="d-sm-flex position-relative h-100" style={{ gap: "30px" }}>
          <div className={`col-sm-6 bg-light px-4 py-4 py-sm-0 my-5 my-sm-0 ${styles.colBorderRadius}`}>
            <div className={`text-center pb-3 ${styles.cardNMargin}`}>
              <img
                src="/images/credit-cards/thumbnail-credit-business-horizontal.jpg"
                alt="WaFd Bank Cash Back Credit Card"
                className="mx-auto text-center border-radius-12 card-box-shadow"
                width={"75%"}
              />
              <div className="mt-4">
                {/* <a
                  id="credit-card-apply-now-cta"
                  class="btn btn-primary w-75"
                  href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card"
                  target="blank"
                >
                  Apply Now
                </a> */}
                <a
                  id="credit-card-apply-now-cta"
                  href="/#"
                  target="_self"
                  className="btn btn-primary w-75"
                  onClick={handleShowZipCodeModal}
                >
                  Apply Now
                </a>
                <ZipCodeFilter
                  showModal={showZipCodeModal}
                  handleCloseModal={handleCloseZipCodeModal}
                  eligibleUrl={
                    "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card"
                  }
                />
              </div>
            </div>
            <h3>
              <strong>Small Business Credit Card</strong>
              <br />
              (Community Banking Credit Card)
            </h3>
            <h4 className="text-success font-weight-bold">
              A personal credit card you can use to track your business expenses
            </h4>
            <p>
              Is your business too new to qualify for credit? Do you want to separate your business expenses from your
              personal finances? This is the perfect cash back card for you!
            </p>
            <List {...cashBackBenefitsListData} accountName="cash-back-cc-benefits" />
            <table id="table-small-business-credit-card" className="table table-bordered">
              <tr>
                <td>
                  <strong>Small Business Credit Card (Community Banking Credit Card)</strong>
                </td>
              </tr>
              <tr>
                <td>
                  A lower credit limit with a competitive rate that earns you cash back via automated statement credit.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Underwriting is based on your personal credit</strong>. You are personally responsible for the
                  payment of all balances incurred on this account and account activity is provided to credit reporting
                  agencies as applicable.
                </td>
              </tr>
              <tr>
                <td>Add up to 3 authorized users on your account.</td>
              </tr>
              <tr>
                <td>No annual or monthly fee.</td>
              </tr>
            </table>
            <h5 className="text-success">Apply now for Small Business Credit Card (Community Banking Credit Card)</h5>
            <div className="text-center">
              {/* <a
                id="credit-card-apply-now-cta-2"
                class="btn btn-primary mb-4 w-75"
                href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-community-business-cash-back-rewards-visa-credit-card"
                target="blank"
              >
                Apply Now
              </a> */}
              <a
                id="credit-card-apply-now-cta-2"
                href="/#"
                target="_self"
                className="btn btn-primary mb-4 w-75"
                onClick={handleShowZipCodeModal}
              >
                Apply Now
              </a>
              <p>
                <strong>Have questions?</strong> See our{" "}
                <Link to="/business-banking/small-business-credit-card/faq" id="small-business-credit-card-faq-link">
                  Small Business Credit Card FAQs
                </Link>
              </p>
            </div>
            <hr />
            <ul className="list-unstyled">
              {cashBackDocumentsListData.map((document) => (
                <li>
                  <a
                    href={document.url}
                    target="_blank"
                    rel="noreferrer"
                    id={`cash-back-credit-card-document-${document.id}`}
                    className={document.url.includes(".pdf") ? "text-decoration-none" : ""}
                  >
                    {document.url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
                    <span dangerouslySetInnerHTML={{ __html: document.text }} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div
            className={`col-sm-6 bg-light px-4 py-4 py-sm-0 my-5 my-sm-0 ${styles.colBorderRadius} ${styles.customMargin}`}
          >
            <div className={`text-center pb-3 ${styles.cardNMargin}`}>
              <img
                src="/images/credit-cards/thumbnail-credit-commercial-horizontal.jpg"
                alt="WaFd Bank Cash Back Credit Card"
                className="mx-auto text-center border-radius-12 card-box-shadow"
                width={"75%"}
              />
              <div className="mt-4">
                {isMktoFormSubmitted ? (
                  <>
                    <h3>Thank you!</h3>
                    <p>
                      Your information has successfully been submitted. A Commercial Banker in your area will connect
                      with you shortly.
                    </p>
                  </>
                ) : (
                  <a
                    id="contact-commercial-banker-form-button"
                    type="button"
                    href="/#"
                    className="btn btn-primary w-75"
                    onClick={(e) => {
                      e.preventDefault();
                      showMktoForm(1067);
                    }}
                  >
                    Get Started
                  </a>
                )}
              </div>
            </div>
            <h3>
              <strong>
                WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred Solution Credit Card
              </strong>
            </h3>
            <h4 className="text-success font-weight-bold">
              A business card designed to meet the needs of company owners
            </h4>
            <p>
              This card offers personal preferences and rewards for business expenses, travel and entertainment
              (T&amp;E), and purchases of goods and services.
            </p>
            <List {...commercialCardBenefitsListData} accountName="commercial-cc-benefits" />
            <table id="table-wafd-bank-visa" className="table table-bordered">
              <tr>
                <td>
                  <strong>
                    WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred Solution Credit Card
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  A larger credit limit for mid-market size businesses, offering rewards you can actually use, including
                  travel perks, gift cards, and statement credit options.
                </td>
              </tr>
              <tr>
                <td>Guarantor may be required.</td>
              </tr>
              <tr>
                <td>Add company cardholders (individual card limits are limited by monthly charge amount).</td>
              </tr>
              <tr>
                <td>No annual or monthly fee.</td>
              </tr>
            </table>
            <h5 className="text-success">
              Contact a Commercial Banker to get started with WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred
              Solution Credit Card
            </h5>
            <div className="text-center mt-4">
              <a
                id="contact-commercial-banker-form-button-2"
                type="button"
                className="btn btn-primary w-75"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  showMktoForm(1067);
                }}
              >
                Get Started
              </a>
            </div>
            <hr />
            <ul className="list-unstyled">
              {commercialCashBackDocumentsListData.map((document) => (
                <li>
                  <a
                    href={document.url}
                    target="_blank"
                    rel="noreferrer"
                    className={document.url.includes(".pdf") ? "text-decoration-none" : ""}
                    id={`commercial-cash-back-document-${document.id}`}
                  >
                    {document.url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
                    <span dangerouslySetInnerHTML={{ __html: document.text }} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="container pt-0 text-sm text-gray-60">
        <p id="sb-credit-card-disclaimer-1">
          * 2% cash back can be earned for gas, grocery, and pharmacy purchases for Visa Signature<sup>&reg;</sup>{" "}
          cardholders and 1% cash back earned for all qualified purchases for both Visa<sup>&reg;</sup> and Visa
          Signature<sup>&reg;</sup> cardholders.
        </p>
        <p id="sb-credit-card-disclaimer-2">
          ** Subject to credit approval. Certain terms, conditions, and exclusions apply. Please refer to your Visa
          Guide to Benefits and Cardholder Agreement for details.
        </p>
        <p id="privacy-notice">
          The WaFd Bank Privacy Notice is available{" "}
          <Link id="privacy-notice-link" to="/privacy-center">
            here
          </Link>
          .
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default SmallBusinessCreditCard;
